import React from "react";

interface DetailLayoutProps {
  children: React.ReactNode;
}

const DetailLayout: React.FC<DetailLayoutProps> = ({ children }) => {
  return (
    <main className="relative h-screen w-full overflow-hidden">{children}</main>
  );
};

export default DetailLayout;
