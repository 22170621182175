import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SCREEN_WIDTHS } from "@/constants";
import { useContentContext } from "@/context/ContentContext";
import darkIslandImage from "@/assets/images/dark-island.jpg";
import mobileBackgroundVideo from "@/assets/video/mobile-bg.mp4";
import desktopBackgroundVideo from "@/assets/video/desktop-bg.mp4";

import "./styles.css";

const VideoBg: React.FC = () => {
  const { isExpandBottom } = useContentContext();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      setKey((prevKey) => prevKey + 1);
    }
  }, []);

  useEffect(() => {
    const videoElement = document.getElementById("vid") as HTMLVideoElement;
    if (videoElement) {
      videoElement.play();
    }
  }, [key]);

  const handleVideoLoad = () => {
    setTimeout(() => {
      setIsVideoLoaded(true);
    }, 500);
  };

  return (
    <>
      {!isVideoLoaded && (
        <div className="absolute left-0 top-0 z-[100] flex h-screen w-full items-center justify-center bg-black">
          <div
            className="triangle"
            style={{
              backgroundImage: `url(${darkIslandImage})`,
              opacity: isVideoLoaded ? 0 : 1,
              transition: "opacity 0.5s ease",
            }}
          />
        </div>
      )}
      <video
        key={key}
        id="vid"
        className={classNames(
          "fixed left-0 top-0 -z-10 h-full w-full object-cover",
          {
            "h-0 duration-[900ms]": isExpandBottom,
            "h-full duration-200": !isExpandBottom,
          },
        )}
        autoPlay
        muted
        loop
        playsInline
        preload="auto"
        onLoadedData={handleVideoLoad}
      >
        <source
          src={mobileBackgroundVideo}
          type="video/mp4"
          media={`(max-width: ${SCREEN_WIDTHS.TABLET - 1}px)`}
        />
        <source
          src={desktopBackgroundVideo}
          type="video/mp4"
          media={`(min-width: ${SCREEN_WIDTHS.TABLET}px)`}
        />
      </video>
    </>
  );
};

export default VideoBg;
